<template>
  <div class="labels-dictionaries">
    <div class="row">
      <div class="col-lg-4">
        <ContentWrapper>
          <WOVehiclesIndex>
            <template slot="title">
              <h4>{{ $t('system.vehicles') }}</h4>
            </template>
          </WOVehiclesIndex>
        </ContentWrapper>
      </div>
      <div class="col-lg-4">
        <ContentWrapper>
          <ElementsList
              :model="tools.model"
              :params="tools.params"
              :thead="tools.thead"
              :title="$t('system_settings.tools')"
              :total="tools.total"
              @confirmEdit="confirmEdit(...arguments, 'tools')"
              @hook:mounted="fetchList('tools')"
              @removeElement="removeElement(...arguments, 'tools')"
              @saveTags="saveTags(...arguments, 'tools')"
              @sortChange="sortChange(...arguments, 'tools')"
              @updateList="fetchList(...arguments, 'tools')"
              ref="tools"
          />
        </ContentWrapper>
      </div>
      <div class="col-lg-4">
        <ContentWrapper>
          <ElementsList
              :model="equipment.model"
              :params="equipment.params"
              :thead="equipment.thead"
              :title="$t('system_settings.equipment')"
              :total="equipment.total"
              @confirmEdit="confirmEdit(...arguments, 'equipment')"
              @hook:mounted="fetchList('equipment')"
              @removeElement="removeElement(...arguments, 'equipment')"
              @saveTags="saveTags(...arguments, 'equipment')"
              @sortChange="sortChange(...arguments, 'equipment')"
              @updateList="fetchList(...arguments, 'equipment')"
              ref="equipment"
          />
        </ContentWrapper>
      </div>
      <div class="col-lg-4">
        <ContentWrapper>
          <ElementsList
              :model="products.model"
              :params="products.params"
              :thead="products.thead"
              :title="$t('system_settings.products')"
              :total="products.total"
              @confirmEdit="confirmEdit(...arguments, 'products')"
              @hook:mounted="fetchList('products')"
              @removeElement="removeElement(...arguments, 'products')"
              @saveTags="saveTags(...arguments, 'products')"
              @sortChange="sortChange(...arguments, 'products')"
              @updateList="fetchList(...arguments, 'products')"
              ref="products"
          />
        </ContentWrapper>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import notification from '../../../notification/notify';
import ElementsList from '../../../components/ElementsList/ElementsList';
import ContentWrapper from '../../../components/HOCs/ContentWrapper';
import WOVehiclesIndex from '@/components/WorkOrderComponents/Vehicles/Index';

export default {
  name: 'WorkOrdersIndex',
  components: {WOVehiclesIndex, ContentWrapper, ElementsList},
  data: function () {
    return {
      tools: {
        model: [],
        source: 'workorders/tools',
        thead: [
          {
            label: 'workOrders.id',
            key: 'id',
            sort: true,
          },
          {
            label: 'workOrders.name',
            key: 'name',
            sort: true,
            edit: true,
          },
          {
            label: '',
            key: 'action',
            sort: false,
            type: 'action',
          },
        ],
        total: 0,
        params: {
          search: '',
          column: 'name',
          direction: 'desc',
          per_page: 10,
          page: 1,
        },
      },
      equipment: {
        model: [],
        source: 'workorders/equipments',
        thead: [
          {
            label: 'workOrders.id',
            key: 'id',
            sort: true,
          },
          {
            label: 'workOrders.name',
            key: 'name',
            sort: true,
            edit: true,
          },
          {
            label: '',
            key: 'action',
            sort: false,
            type: 'action',
          },
        ],
        total: 0,
        params: {
          search: '',
          column: 'name',
          direction: 'desc',
          per_page: 10,
          page: 1,
        },
      },
      products: {
        model: [],
        source: 'workorders/products',
        thead: [
          {
            label: 'workOrders.id',
            key: 'id',
            sort: true,
          },
          {
            label: 'workOrders.name',
            key: 'name',
            sort: true,
            edit: true,
          },
          {
            label: '',
            key: 'action',
            sort: false,
            type: 'action',
          },
        ],
        total: 0,
        params: {
          search: '',
          column: 'name',
          direction: 'desc',
          per_page: 10,
          page: 1,
        },
      },
    };

  },
  methods: {
    async saveTags(tagsToSave, category) {
      try {
        await axios.post(`${this[category].source}`, {nameList: tagsToSave.map(tag => tag.text)});
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_create'),
            'error');
      }
      await this.fetchList(category);
    },
    async removeElement(item, category) {
      await axios.delete(`${this[category].source}/${item.id}`);
      await this.fetchList(category);
    },
    sortChange(column, category) {
      if (column === this[category].params.column) {
        if (this[category].params.direction === 'desc') {
          this[category].params.direction = 'asc';
        } else {
          this[category].params.direction = 'desc';
        }
      } else {
        this[category].params.column = column;
        this[category].params.direction = 'asc';
      }
      this.fetchList(category);
    },
    async confirmEdit(row, category) {
      try {
        await axios.put(`${this[category].source}/${row.id}`, {name: row.name});
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_update'),
            'error');
      }
      await this.fetchList(category);
    },
    async fetchList(category) {
      try {
        const {data} = await axios.get(`${this[category].source}`, {params: this[category].params});
        this[category].total = data.total;
        this[category].model = data.data.map(item => ({...item, edit: false}));
      } catch (e) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_load'),
            'error');
      }
    },
  },
};
</script>

<style lang="scss">
.v-text-field__slot .v-label {
  font-weight: normal;
}

.vue-tags-input {
  max-width: unset !important;
  flex: auto;
  margin-right: 15px;

  .ti-input {
    min-height: 37px;
    height: auto;

    .ti-tags {
      line-height: 1.22em !important;

      .ti-tag {
        padding: 3px 6px;
        font-size: .95em !important;
      }
    }
  }
}
</style>
