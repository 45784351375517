<template>
  <div>
    <v-row>
      <v-col
          cols="12"
          md="6"
      >
        <h4 class="mt-2">
          {{ title }}
        </h4>
      </v-col>
      <v-col
          class="ml-auto"
          cols="12"
          md="4"
      >
        <v-text-field
            :label="$t('system.search')"
            @input="updateList"
            class="mt-2 pt-0"
            v-model="params.search"
        />
      </v-col>
    </v-row>
    <hr class="mt-0">
    <v-row>
      <v-col class="col-12 d-flex">
        <VueTagsInput
            :add-on-key="[13, ',', ';']"
            :autocomplete-items="filteredItems"
            :placeholder="$t('system.add_tag')"
            :tags="tagsToSave"
            @tags-changed="newTags => tagsToSave = newTags"
            class="input"
            v-model="newTag"
        />
        <v-btn
            @click="saveTags"
            color="success"
            dark
        >
          {{ $t('system.create') }}
        </v-btn>
      </v-col>
    </v-row>
    <hr>
    <StaticTable
        :data="model"
        :params="params"
        :thead="thead"
        @cancelEdit="cancelEdit"
        @confirmEdit="confirmEdit"
        @editElement="editElement"
        @removeElement="removeElement(...arguments, 'tools')"
        @sortChange="sortChange"
    />
    <el-card
        class="pagination-container mt-3"
        shadow="never"
    >
      <el-pagination
          :current-page="params.page"
          :page-size="params.per_page"
          :page-sizes="[10, 30, 50, 100, 300]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import StaticTable from '../ABtable/StaticTable';
import VueTagsInput from '@johmun/vue-tags-input';

export default {
  name: 'ElementsList',
  components: {StaticTable, VueTagsInput},
  props: {
    title: {
      type: String,
    },
    thead: {
      type: Array,
      default: () => [],
    },
    model: {
      type: Array,
      default: () => [],
    },
    total: {
      type: [Number, String],
      default: 0,
    },
    params: {
      type: Object,
    },
  },
  data: () => ({
    newTag: '',
    tagsToSave: [],
    previousRowValue: null,
  }),
  // mounted() {
  //   this.$nextTick(() => {
  //     this.$emit('mounted')
  //   })
  // },
  computed: {
    filteredItems() {
      return [];
    },
  },
  methods: {
    sortChange(column) {
      this.$emit('sortChange', column);
    },
    editElement(row) {
      this.previousRowValue = {...row};
      row.edit = true;
    },
    cancelEdit(row) {
      Object.assign(row, {...this.previousRowValue, edit: false});
      this.previousRowValue = null;
    },
    confirmEdit(row) {
      this.$emit('confirmEdit', row);
    },
    removeElement(row) {
      this.$emit('removeElement', row);
    },
    handleSizeChange(newSize) {
      this.params.per_page = newSize;
      this.updateList();
    },
    handleCurrentChange(newPageNumber) {
      this.params.page = newPageNumber;
      this.updateList();
    },
    saveTags() {
      this.$emit('saveTags', this.tagsToSave);
      this.tagsToSave = [];
    },
    updateList() {
      this.$emit('updateList');
    },
  },
};
</script>

<style scoped>

</style>
