<template>
  <div>
    <div class="d-flex justify-content-between">
      <div>
        <slot name="title" />
      </div>
      <el-button
          @click="openForm"
          size="mini"
          type="primary"
      >
        {{ $t('system.create') }}
      </el-button>
    </div>
    <DataTable
        :headers="headers"
        :model="data"
        :params.sync="params"
        @clickRow="onRowClicked"
    >
      <template v-slot:item.options="{row}">
        <div class="d-flex mx-2 justify-content-center">
          <el-button
              @click.stop="openEditForm(row.id)"
              class="mr-2"
              size="mini"
              type="info"
          >
            {{ $t('system.edit') }}
          </el-button>
          <el-popconfirm
              :cancelButtonText="$t('system.no')"
              :confirmButtonText="$t('system.yes')"
              :title="$t('system.are_you_sure')"
              @onConfirm="remove(row)"
              iconColor="red"
          >
            <el-button
                @click.stop
                size="mini"
                slot="reference"
                type="danger"
            >
              {{ $t('system.delete') }}
            </el-button>
          </el-popconfirm>
        </div>
      </template>
    </DataTable>
    <el-dialog
        :title="isDetailsDialogOpen ? $t('system.vehicle_info') : $t('system.add_vehicle')"
        :visible="isFormOpen || isDetailsDialogOpen"
        :width="isFormOpen ? '1000px' : '80%'"
        @close="closeDialog"
        class="dialog-pb-0"
    >
      <VehicleForm
          :id="vehicleId"
          @close="closeDialog"
          v-if="isFormOpen"
      />
      <VehicleDetails
          :id="vehicleId"
          v-if="isDetailsDialogOpen"
      />
    </el-dialog>

  </div>
</template>

<script>
import axios from 'axios';
import DataTable from '@/components/Table/DataTable';
import notification from '../../../notification/notify';

export default {
  name: 'WOVehiclesIndex',
  components: {
    VehicleDetails: () => import('@/components/WorkOrderComponents/Vehicles/VehicleDetails'),
    DataTable,
    VehicleForm: () => import('@/components/WorkOrderComponents/Vehicles/VehicleForm'),
  },
  data: () => ({
    data: [],
    headers: [
      {
        key: 'id',
        label: 'system.id',
        justify: 'center',
      },
      {
        label: 'system.name',
        key: 'name',
      },
      {
        label: 'system.options',
        key: 'options',
        sort: false,
      },
    ],
    params: {
      column: 'id',
      direction: 'asc',
      per_page: 10,
      page: 1,
      total: 0,
    },
    isFormOpen: false,
    isDetailsDialogOpen: false,
    vehicleId: null,
  }),
  created() {
    this.fetchData();
  },
  methods: {
    async remove(row) {
      try {
        await axios.delete(`workorders/vehicles/${row.id}`);
        await this.fetchData();
        notification.notify(
            this.$t('notify.success'),
            this.$t('system.removed'),
            'success');
      } catch (error) {
        notification.notify(
            this.$t('notify.error'),
            this.$t('system.can_not_remove'),
            'error');
      }
    },
    closeDialog(refreshList = false) {
      this.isFormOpen = false;
      this.isDetailsDialogOpen = false;
      this.vehicleId = null;
      if (refreshList) {
        this.fetchData();
      }
    },
    openForm() {
      this.isFormOpen = true;
    },
    openDetailsDialog() {
      this.isDetailsDialogOpen = true;
    },
    async fetchData() {
      try {
        const {data} = await axios.get('workorders/vehicles');
        this.data = data.data;
        this.params.total = data.total;
      } catch (error) {
        console.log('listError', error);
      }
    },
    onRowClicked(id) {
      this.vehicleId = id;
      this.openDetailsDialog();
    },
    openEditForm(id) {
      this.vehicleId = id;
      this.openForm();
    },
  },
};
</script>

<style scoped>

</style>
