var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"labels-dictionaries"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('ContentWrapper',[_c('WOVehiclesIndex',[_c('template',{slot:"title"},[_c('h4',[_vm._v(_vm._s(_vm.$t('system.vehicles')))])])],2)],1)],1),_c('div',{staticClass:"col-lg-4"},[_c('ContentWrapper',[_c('ElementsList',{ref:"tools",attrs:{"model":_vm.tools.model,"params":_vm.tools.params,"thead":_vm.tools.thead,"title":_vm.$t('system_settings.tools'),"total":_vm.tools.total},on:{"confirmEdit":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.confirmEdit.apply(void 0, argsArray.concat( ['tools'] ))},"hook:mounted":function($event){return _vm.fetchList('tools')},"removeElement":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.removeElement.apply(void 0, argsArray.concat( ['tools'] ))},"saveTags":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.saveTags.apply(void 0, argsArray.concat( ['tools'] ))},"sortChange":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sortChange.apply(void 0, argsArray.concat( ['tools'] ))},"updateList":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.fetchList.apply(void 0, argsArray.concat( ['tools'] ))}}})],1)],1),_c('div',{staticClass:"col-lg-4"},[_c('ContentWrapper',[_c('ElementsList',{ref:"equipment",attrs:{"model":_vm.equipment.model,"params":_vm.equipment.params,"thead":_vm.equipment.thead,"title":_vm.$t('system_settings.equipment'),"total":_vm.equipment.total},on:{"confirmEdit":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.confirmEdit.apply(void 0, argsArray.concat( ['equipment'] ))},"hook:mounted":function($event){return _vm.fetchList('equipment')},"removeElement":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.removeElement.apply(void 0, argsArray.concat( ['equipment'] ))},"saveTags":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.saveTags.apply(void 0, argsArray.concat( ['equipment'] ))},"sortChange":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sortChange.apply(void 0, argsArray.concat( ['equipment'] ))},"updateList":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.fetchList.apply(void 0, argsArray.concat( ['equipment'] ))}}})],1)],1),_c('div',{staticClass:"col-lg-4"},[_c('ContentWrapper',[_c('ElementsList',{ref:"products",attrs:{"model":_vm.products.model,"params":_vm.products.params,"thead":_vm.products.thead,"title":_vm.$t('system_settings.products'),"total":_vm.products.total},on:{"confirmEdit":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.confirmEdit.apply(void 0, argsArray.concat( ['products'] ))},"hook:mounted":function($event){return _vm.fetchList('products')},"removeElement":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.removeElement.apply(void 0, argsArray.concat( ['products'] ))},"saveTags":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.saveTags.apply(void 0, argsArray.concat( ['products'] ))},"sortChange":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.sortChange.apply(void 0, argsArray.concat( ['products'] ))},"updateList":function($event){
var i = arguments.length, argsArray = Array(i);
while ( i-- ) argsArray[i] = arguments[i];
return _vm.fetchList.apply(void 0, argsArray.concat( ['products'] ))}}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }